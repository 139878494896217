export const PAGE_NEW_DO = 'newDO';
export const PAGE_LOAD_BOARDS = 'loadBoards';
export const PAGE_DRIVERS_CARD = 'driversCard';
export const PAGE_CARRIER_PROFILE = 'carrierProfile';
export const PAGE_TYPE_CLAIM__DETAILS = 'claimDetails';
export const PAGE_NEW_DO_QUOTE_EDIT = 'newDOQuoteEdit';
export const PAGE_DISPATCH_PLANNER = 'dispatchPlanner';
export const PAGE_DISPATCH_BOARD_NEW = 'dispatchBoardNew';
export const PAGE_INVOICE_EDI_REPORT = 'invoiceEdiReport';
export const PAGE_DISPATCH_PLANNER_EVENTS = 'dispatchPlannerEvents';
export const PAGE_DISPATCH_DETAILS_NEW_LOAD = 'dispatchDetailsNewLoad';
export const PAGE_DISPATCH_DETAILS_NEW_ORDER = 'dispatchDetailsNewOrder';
// invoices
export const PAGE_MASTER_INVOICE_REPORT = 'pageMasterInvoiceReport';
export const PAGE_CUSTOMER_INVOICE_REPORT = 'pageCustomerInvoiceReport';
// fleet profiles
export const PAGE_FLEET_TRUCK_PROFILE = 'fleeTruckProfile';
export const PAGE_FLEET_DRIVER_PROFILE = 'fleeDriverProfile';
export const PAGE_FLEET_VENDOR_PROFILE = 'fleeVendorProfile';
export const PAGE_FLEET_TRAILER_PROFILE = 'fleeTrailerProfile';
// fleet profiles v2
export const PAGE_FLEET_TRUCK_PROFILE_V2 = 'fleeTruckProfile_v2';
export const PAGE_FLEET_DRIVER_PROFILE_V2 = 'fleeDriverProfile_v2';
export const PAGE_FLEET_VENDOR_PROFILE_V2 = 'fleeVendorProfile_v2';
export const PAGE_FLEET_TRAILER_PROFILE_V2 = 'fleeTrailerProfile_v2';
