export const EXTENSION_JPG = 'jpg';
export const EXTENSION_GIF = 'gif';
export const EXTENSION_PNG = 'png';
export const EXTENSION_JPE = 'jpe';
export const EXTENSION_TIF = 'tif';
export const EXTENSION_SVG = 'svg';
export const EXTENSION_PDF = 'pdf';
export const EXTENSION_ICO = 'ico';
export const EXTENSION_CSV = 'csv';
export const EXTENSION_JFIF = 'jfif';
export const EXTENSION_JPEG = 'jpeg';
export const EXTENSION_WBMP = 'wbmp';
export const EXTENSION_WEBP = 'webp';
export const EXTENSION_SVGZ = 'svgz';
export const EXTENSION_XLSX = 'xlsx';
export const EXTENSION_DOCX = 'docx';
export const EXTENSION_PJPEG = 'pjpeg';

export const FILE_PREVIEW_SUPPORTED_EXTENSIONS = [
  EXTENSION_PNG,
  EXTENSION_GIF,
  EXTENSION_PDF,
  EXTENSION_CSV,
  EXTENSION_JPG,
  EXTENSION_JPEG,
  EXTENSION_XLSX,
  EXTENSION_DOCX,
];

export const IMAGE_EXTENSIONS = [
  EXTENSION_GIF,
  EXTENSION_ICO,
  EXTENSION_JPE,
  EXTENSION_JPG,
  EXTENSION_PNG,
  EXTENSION_TIF,
  EXTENSION_JFIF,
  EXTENSION_JPEG,
  EXTENSION_SVGZ,
  EXTENSION_WBMP,
  EXTENSION_WEBP,
  EXTENSION_PJPEG,
];

export const DOCUMENTS_EXTENSIONS = [
  ...IMAGE_EXTENSIONS,
  EXTENSION_PDF,
];
