export const MENU_ACTION_ROUTE = 'route';
export const MENU_ACTION_IMPORTS = 'imports';
export const MENU_ACTION_CLO_LIST = 'cloList';
export const MENU_ACTION_TEL_LIST = 'telList';
export const MENU_ACTION_ANALYTICS = 'analytic';
export const MENU_ACTION_RATE_SHOP = 'rateShop';
export const MENU_ACTION_CONFIG_UI = 'configUI';
export const MENU_ACTION_FUEL_CARDS = 'fuelCards';
export const MENU_ACTION_ITEMS_LIST = 'itemsList';
export const MENU_ACTION_CHART_LIST = 'chartList';
export const MENU_ACTION_ROLES_LIST = 'rolesList';
export const MENU_ACTION_CONFIG_TEL = 'configTEL';
export const MENU_ACTION_USERS_LIST = 'usersList';
export const MENU_ACTION_CREATE_CLO = 'createCLO';
export const MENU_ACTION_CONFIG_CLO = 'configCLO';
export const MENU_ACTION_ROUTE_BETA = 'routeBeta';
export const MENU_ACTION_ROUTE_LIST = 'routeList';
export const MENU_ACTION_DASHBOARDS = 'dashboards';
export const MENU_ACTION_BRANCH_LIST = 'branchList';
export const MENU_ACTION_IFTA_REPORT = 'iftaReport';
export const MENU_ACTION_TOLL_CHARGES = 'tollCharges';
export const MENU_ACTION_DRIVERS_CARD = 'driversCard';
export const MENU_ACTION_CONTACT_BOOK = 'contactBook';
export const MENU_ACTION_CONFIG_AUDIT = 'configAudit';
export const MENU_ACTION_CARRIER_LIST = 'carrierList';
export const MENU_ACTION_CONFIG_TRUCK = 'configTruck';
export const MENU_ACTION_LOAD_PLANNER = 'loadPlanner';
export const MENU_ACTION_DISPATCH_EDI = 'dispatchEDI';
export const MENU_ACTION_IMPORT_REPORT = 'importReport';
export const MENU_ACTION_CONFIG_MASTER = 'configMaster';
export const MENU_ACTION_CONFIG_RATING = 'configRating';
export const MENU_ACTION_ANALYTIC_MAPS = 'analyticMaps';
export const MENU_ACTION_CONFIG_DRIVER = 'configDriver';
export const MENU_ACTION_ROUTE_BUILDER = 'routeBuilder';
export const MENU_ACTION_LOCATION_LIST = 'locationsList';
export const MENU_ACTION_SEQUENCE_LIST = 'sequencesList';
export const MENU_ACTION_CONFIG_GENERAL = 'configGeneral';
export const MENU_ACTION_CONFIG_TRAILER = 'configTrailer';
export const MENU_ACTION_CONFIG_CARRIER = 'configCarrier';
export const MENU_ACTION_STYLING_CONFIG = 'stylingConfig';
export const MENU_ACTION_CHARGE_REPORTS = 'chargeReports';
export const MENU_ACTION_TEMPLATES_DO_LIST = 'doTemplates';
export const MENU_ACTION_ROUTE_TEMPLATES = 'routeTemplates';
export const MENU_ACTION_CONFIG_INVOICES = 'configInvoices';
export const MENU_ACTION_CONFIG_PASSWORD = 'configPassword';
export const MENU_ACTION_CUSTOMER_PORTAL = 'customerPortal';
export const MENU_ACTION_TASK_MANAGEMENT = 'taskManagement';
export const MENU_ACTION_GEO_FENCING_ZONE = 'geoFencingZone';
export const MENU_ACTION_CONFIG_MOBILEAPP = 'configMobileApp';
export const MENU_ACTION_CLAIM_MANAGEMENT = 'claimManagement';
export const MENU_ACTION_CONFIG_TEMPLATES = 'configTemplates';
export const MENU_ACTION_CONFIG_FLEET_GENERAL = 'fleetGeneral';
export const MENU_ACTION_FLEET_VENDOR_LIST = 'fleetVendorList';
export const MENU_ACTION_FLEET_TRUCKS_LIST = 'fleetTrucksList';
export const MENU_ACTION_AVAILABLE_DRIVERS = 'availableDrivers';
export const MENU_ACTION_DISPATCHING_GROUP = 'dispatchingGroup';
export const MENU_ACTION_FLEET_DRIVERS_LIST = 'fleetDriversList';
export const MENU_ACTION_AVERAGE_FUEL_PRICE = 'averageFuelPrice';
export const MENU_ACTION_DISPATCH_ORDER_API = 'dispatchOrderAPI';
export const MENU_ACTION_CONFIG_INTEGRATION = 'configIntegration';
export const MENU_ACTION_EXTERNAL_LOADBOARD = 'externalloadboard';
export const MENU_ACTION_CONTAINER_TYPE_LIST = 'containerTypeList';
export const MENU_ACTION_FLEET_TRAILERS_LIST = 'fleetTrailersList';
export const MENU_ACTION_DRIVER_PAYROLL_LIST = 'driverPayrollList';
export const MENU_ACTION_REPORT_FORMATS_LIST = 'reportFormatsList';
export const MENU_ACTION_SERVICE_VENDOR_LIST = 'serviceVendorList';
export const MENU_ACTION_DRIVER_INVOICES_LIST = 'driverInvoiceList';
export const MENU_ACTION_VENDOR_INVOICES_LIST = 'vendorInvoiceList';
export const MENU_ACTION_COMMISSION_ASSIGNEE = 'commissionAssignee';
export const MENU_ACTION_REFERENCE_TYPES_LIST = 'referenceTypesList';
export const MENU_ACTION_DISPATCH_CARRIER_EDI = 'dispatchCarrierEDI';
export const MENU_ACTION_CARRIER_INVOICES_LIST = 'carrierInvoiceList';
export const MENU_ACTION_CONFIG_COMMUNICATION = 'configCommunication';
export const MENU_ACTION_LOAD_PLANNER_TEMPLATE = 'loadPlannerTemplate';
export const MENU_ACTION_CONFIG_SERVICE_VENDOR = 'configServiceVendor';
export const MENU_ACTION_CARRIER_CONTRACT_LIST = 'carrierContractList';
export const MENU_ACTION_CARRIER_RATING_REPORT = 'carrierRatingReport';
export const MENU_ACTION_DISPATCH_ORDER_QUOTES = 'dispatchOrderQuotes';
export const MENU_ACTION_CUSTOMER_INVOICES_LIST = 'customerInvoiceList';
export const MENU_ACTION_CUSTOMER_CONTRACT_LIST = 'customerContractList';
export const MENU_ACTION_CONFIG_TASK_MANAGEMENT = 'configTaskManagement';
export const MENU_ACTION_CURRENCY_EXCHANGE_RATE = 'currencyExchangeRate';
export const MENU_ACTION_GEO_FENCING_ZONE_REPORT = 'geoFencingZoneReport';
export const MENU_ACTION_CONFIG_CLAIM_MANAGEMENT = 'configClaimManagement';
export const MENU_ACTION_SHARED_ACCESSORIAL_LIST = 'sharedAccessorialList';
export const MENU_ACTION_CONTAINER_TEMPLATE_LIST = 'containerTemplateList';
export const MENU_ACTION_ROUTE_MASS_CREATE_REPORT = 'routeMassCreateReport';
export const MENU_ACTION_PAYROLL_ACCESSORIAL_LIST = 'payrollAccessorialList';
export const MENU_ACTION_DISPATCH_EXTERNAL_QUOTES = 'dispatchExternalQuotes';
export const MENU_ACTION_SERVICE_VENDOR_INVOICE_LIST = 'serviceVendorInvoice';
export const MENU_ACTION_COMPENSATION_TEMPLATE_LIST = 'compensationTemplateList';
export const MENU_ACTION_FLEET_SHARED_COMPONENT_LIST = 'fleetSharedComponentList';
export const MENU_ACTION_CUSTOMER_MASTER_INVOICE_LIST = 'customerMasterInvoiceList';
export const MENU_ACTION_FLEET_EQUIPMENT_SERVICES_LIST = 'fleetEquipmentServicesList';
