export const BRANCH_TYPE_ENUM_MASTER = 'MASTER';
export const BRANCH_TYPE_ENUM_CUSTOMER = 'CUSTOMER';
export const BRANCH_TYPE_ENUM_DIVISION = 'DIVISION';

export const BRANCH_TYPE_BROKER = 'Broker';
export const BRANCH_TYPE_MASTER = 'Master';
export const BRANCH_TYPE_CUSTOMER = 'Customer';
export const BRANCH_TYPE_TERMINAL = 'Terminal';
export const BRANCH_TYPE_DIVISION = 'Division';
export const BRANCH_TYPE_WAREHOUSE = 'Warehouse';
export const BRANCH_TYPE_BROKER_STORED_VALUE = 'BRK';
export const BRANCH_TYPE_MASTER_STORED_VALUE = 'MST';
export const BRANCH_TYPE_CUSTOMER_STORED_VALUE = 'CST';
export const BRANCH_TYPE_TERMINAL_STORED_VALUE = 'TRM';
export const BRANCH_TYPE_DIVISION_STORED_VALUE = 'DVS';
export const BRANCH_TYPE_WAREHOUSE_STORED_VALUE = 'WHS';

export const branchTypeDisplayMap = {
  [BRANCH_TYPE_ENUM_MASTER]: BRANCH_TYPE_MASTER_STORED_VALUE,
  [BRANCH_TYPE_ENUM_CUSTOMER]: BRANCH_TYPE_CUSTOMER_STORED_VALUE,
  [BRANCH_TYPE_ENUM_DIVISION]: BRANCH_TYPE_DIVISION_STORED_VALUE,
};

const branchLowercase = 'enterprise';
const branchUppercase = 'ENTERPRISE';
const branchCapitalize = 'Enterprise';

export const BRANCH_LOWERCASE = branchLowercase;
export const BRANCH_UPPERCASE = branchUppercase;
export const BRANCH_CAPITALIZE = branchCapitalize;

export const BRANCHES = `${BRANCH_UPPERCASE}S`;
export const BRANCH_NAME = `${BRANCH_LOWERCASE}Name`;
export const BRANCH_TYPE = `${BRANCH_LOWERCASE}Type`;
export const BRANCH_GUID = `${BRANCH_LOWERCASE}Guid`;
export const BRANCH_LIST = `${BRANCH_LOWERCASE}sList`;
export const BRANCH_GUIDS = `${BRANCH_LOWERCASE}Guids`;
export const CURRENT_BRANCH = `current${BRANCH_CAPITALIZE}`;
export const NEW_BRANCH_GUID = `new${BRANCH_CAPITALIZE}Guid`;
export const OWNER_BRANCH_GUID = `owner${BRANCH_CAPITALIZE}Guid`;
export const DRAFT_BRANCH_GUID = `draft${BRANCH_CAPITALIZE}Guid`;
export const OWNING_BRANCH_GUID = `owning${BRANCH_CAPITALIZE}Guid`;
export const GRANTED_BRANCH_GUID = `granted${BRANCH_CAPITALIZE}Guid`;
export const CURRENT_BRANCH_GUID = `current${BRANCH_CAPITALIZE}Guid`;
export const CUSTOMER_BRANCH_GUID = `customer${BRANCH_CAPITALIZE}Guid`;
export const CUSTOMER_BRANCH_NAME = `customer${BRANCH_CAPITALIZE}Name`;
export const PINNED_BRANCHES_GUIDS = `pinned${BRANCH_CAPITALIZE}sGuids`;
