export const CHECK_IN_ACTION = 'CHECK_IN_ACTION';
export const COMPLETE_ACTION = 'COMPLETE_ACTION';
export const CREATE_TEL_ACTION = 'CREATE_TEL_ACTION';
export const CANCEL_CLO_ACTION = 'CANCEL_CLO_ACTION';
export const UNQUOTE_CLO_ACTION = 'UNQUOTE_CLO_ACTION';
export const RESTORE_CLO_ACTION = 'RESTORE_CLO_ACTION';
export const ACCEPT_LOAD_ACTION = 'ACCEPT_LOAD_ACTION';
export const ADD_DOCUMENT_ACTION = 'ADD_DOCUMENT_ACTION';
export const DECLINE_LOAD_ACTION = 'DECLINE_LOAD_ACTION';
export const EDIT_CLO_RATE_ACTION = 'EDIT_CLO_RATE_ACTION';
export const DISPATCH_LOAD_ACTION = 'DISPATCH_LOAD_ACTION';
export const ADD_APPOINTMENT_ACTION = 'ADD_APPOINTMENT_ACTION';
export const ADD_DRIVER_RATE_ACTION = 'ADD_DRIVER_RATE_ACTION';
export const DUPLICATE_ORDER_ACTION = 'DUPLICATE_ORDER_ACTION';
export const RE_DISPATCH_LOAD_ACTION = 'RE_DISPATCH_LOAD_ACTION';
export const ADD_CARRIER_RATE_ACTION = 'ADD_CARRIER_RATE_ACTION';
export const BOOK_NO_DISPATCH_ACTION = 'BOOK_NO_DISPATCH_ACTION';
export const ADD_DOCUMENT_POP_ACTION = 'ADD_DOCUMENT_POP_ACTION';
export const ADD_DOCUMENT_POD_ACTION = 'ADD_DOCUMENT_POD_ACTION';
export const CALL_STATUS_CHECK_ACTION = 'CALL_STATUS_CHECK_ACTION';
export const SEND_QUOTE_REQUEST_ACTION = 'SEND_QUOTE_REQUEST_ACTION';
export const ADD_DRIVER_CARRIER_INVOICE = 'ADD_DRIVER_CARRIER_INVOICE';
export const OPEN_IN_ROUTE_BUILDER_ACTION = 'OPEN_IN_ROUTE_BUILDER_ACTION';
export const CANCEL_DISPATCHED_LOAD_ACTION = 'CANCEL_DISPATCHED_LOAD_ACTION';
export const SEND_UPDATE_EDI_OR_API_ACTION = 'SEND_UPDATE_EDI_OR_API_ACTION';
export const CREATE_CUSTOMER_INVOICE_ACTION = 'CREATE_CUSTOMER_INVOICE_ACTION';
export const SEND_TO_EXTERNAL_SYSTEM_ACTION = 'SEND_TO_EXTERNAL_SYSTEM_ACTION';
export const APPLY_ORDER_RATE_UPLIFT_ACTION = 'APPLY_ORDER_RATE_UPLIFT_ACTION';
export const EDIT_DRIVER_CARRIER_RATE_ACTION = 'EDIT_DRIVER_CARRIER_RATE_ACTION';
export const GET_INTEGRATION_DOCUMENTS_ACTION = 'GET_INTEGRATION_DOCUMENTS_ACTION';
export const SEND_TO_CROSS_BORDER_INTEGRATION_ACTION = 'SEND_TO_CROSS_BORDER_INTEGRATION_ACTION';
