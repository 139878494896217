// helpers/constants
import { ENUMS } from './enums';
//////////////////////////////////////////////////

const dayToIsoDayNumberMap = {
  [ENUMS.ENUM_MONDAY]: 1,
  [ENUMS.ENUM_TUESDAY]: 2,
  [ENUMS.ENUM_WEDNESDAY]: 3,
  [ENUMS.ENUM_THURSDAY]: 4,
  [ENUMS.ENUM_FRIDAY]: 5,
  [ENUMS.ENUM_SATURDAY]: 6,
  [ENUMS.ENUM_SUNDAY]: 7,
};

const momentDayNumberMap = {
  [ENUMS.ENUM_SUNDAY]: 0,
  [ENUMS.ENUM_MONDAY]: 1,
  [ENUMS.ENUM_TUESDAY]: 2,
  [ENUMS.ENUM_WEDNESDAY]: 3,
  [ENUMS.ENUM_THURSDAY]: 4,
  [ENUMS.ENUM_FRIDAY]: 5,
  [ENUMS.ENUM_SATURDAY]: 6,
};

export {
  momentDayNumberMap,
  dayToIsoDayNumberMap,
};
